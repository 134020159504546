import { Stack, Box } from "@chakra-ui/react";
import Section from "~/components/Section";
import HomeHero from "~/components/home/topSection/HomeHero";
import CriteriaSearch from "~/components/home/topSection/CriteriaSearch";
import { FC, Suspense } from "react";

const TopSection: FC = () => {
  
  return (
    <Section pt={[2, 4, 12]} pos={"relative"} zIndex={10}>
      <Stack
        direction={["column-reverse", "column-reverse", "row"]}
        alignItems={{ base: "flex-start", lg: "center" }}
        px={[2, 2, 0]}
        spacing={[4, 4, 0]}
      >
        <Suspense>
          <CriteriaSearch />
        </Suspense>

        <Box
          w={["full", "full", "65%"]}
          className="aliceCarouselWrapper"
          borderRadius={"md"}
        >
          <HomeHero filters="home" />
        </Box>
      </Stack>
    </Section>
  );
};

export default TopSection;
