import { FC } from "react";

import { Box, Button, HStack, Spacer, Text } from "@chakra-ui/react";

import Section from "~/components/Section";
import { useGlobalContext } from "~/context/global";
import { useTranslation } from "~/libs/i18n";
import { useLocaleNavigate } from "~/libs/i18n";

const HomeHotKeywordList: FC = () => {
  const t = useTranslation();
  const navigate = useLocaleNavigate();
  const { setting } = useGlobalContext();

  return (
    <Box bg="brand.soft-yellow" w="full" px={[2, 2, 0]}>
      <Section py={4}>
        <HStack w="full" alignItems="center">
          <Text color="brand.timable-yellow" fontSize="md">
            {t("filters.hotKeyword")}
          </Text>

          <Spacer display={{ base: "none", sm: "block" }} w="full" />

          <Box
            overflowX="auto"
            flex={{ base: 1, lg: "none" }}
            css={{
              "&::-webkit-scrollbar": {
                width: "0px",
                height: "0px",
              },
            }}
            textAlign="right"
          >
            <HStack w="max-content" spacing={4} px={2}>
              {setting?.criteria?.tag?.map(({ id, name }, index) => {
                return [
                  index > 0 && <Text key={index}>|</Text>,
                  <Box key={`${id}-${name}`}>
                    <Button
                      color="black"
                      fontWeight="normal"
                      size="sm"
                      variant="link"
                      onClick={() => navigate(`/event?tag=${name}`)}
                    >
                      {name}
                    </Button>
                  </Box>,
                ];
              })}
            </HStack>
          </Box>
        </HStack>
      </Section>
    </Box>
  );
};

export default HomeHotKeywordList;
